import {
  Box, Button, Checkbox, Flex, FormControl,
  FormErrorMessage, Heading, Input, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalHeader, ModalOverlay, Stack, Text, Textarea, UnorderedList, useDisclosure, useToast, VStack
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { useTagCategory } from "../hooks/tag-category";
import { toFormData } from "../util/form-data";
import api from "../services/api";
import { Radio, RadioGroup } from '@chakra-ui/react'
import * as React from "react";
import {HiUpload} from "react-icons/hi";

interface SessionCancelModalProps {
  id: string;
  status: string;
  buttonText?: string;
  variantButton?: string;
}

type SessionReportType = Pick<
  Model.SessionReport,
  "additional_info" | "cancel" | "notes"
>;

const SessionCancelModal: FC<SessionCancelModalProps> = ({
  id,
  status,
  buttonText,
  variantButton,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [relatoRequire, setRelatoRequire] = useState(false);
  const tagCategory = useTagCategory("session-report.cancel");
  const [value, setValue] = useState<SessionReportType>({
    additional_info: {},
    cancel: true,
    notes: "",
  });


  const [canceled, setCanceled] = useState('')
  const [canceledDisplacement, setCanceledDisplacement] = useState(false)
  const isError = value.notes === ''

  const toast = useToast()

  const handleSubmit = () => {

    if (!canceled && value.notes === "") {
      toast({
        title: "Selecione uma tag ou digite o motivo.",
        position: "top-right",
        status: "error",
        duration: 16000,
        isClosable: true,
      });
      return;
    }

    if (canceledDisplacement && fileName == "") {
      toast({
        title: "Envie um arquivo de comprovação de deslocamento.",
        position: "top-right",
        status: "error",
        duration: 16000,
        isClosable: true,
      });
      return;
    }


    const body = {
      ...value,
      session_id: id,
      tag_id: canceled,
      canceled_displacement: Number(canceledDisplacement),
      file_canceled: fileCanceled,
    };

    api.post(`/provider/canceled-session`,  body)
      .then((response) => {
          window.location.reload();
      })
      .catch(() => alert("Falha ao salvar alterações"));
  };

  const [fileName, setFileName] = useState('');
  const [fileCanceled, setFileCanceled] = useState('');

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFileCanceled(file);
      setFileName(file.name);
    }
  };

  return (
    <>
      <Button variant={variantButton} onClick={onOpen}>
        {buttonText ? buttonText : "Cancelar com Deslocamento"}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xs">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md" fontFamily="base">
            Cancelar produção
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Text color={'gray.600'}>Selecione um motivo e/ou descreva o que aconteceu.</Text>
            <RadioGroup onChange={(value)=>setCanceled(value)} value={canceled} mt={3} color={'gray.600'}>
              <Stack direction={'column'}>
                {tagCategory?.tags.map(({ id, name, description }) => {
                  return (
                      <Radio color={'gray.600'} value={String(id)}>{name}</Radio>
                  );
                })}
              </Stack>
            </RadioGroup>
            <Textarea placeholder={'Digite aqui o motivo...'} mt={5}
                onChange={(notes) =>
                  setValue((value) => ({ ...value, notes: notes.target.value }))
                }
            >
            </Textarea>
            {canceledDisplacement &&
                <Box textAlign="center" p={6}>
                  <VStack spacing={4}>
                    <input
                        type="file"
                        accept="application/pdf,image/*"
                        id="file-upload"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <label htmlFor="file-upload">
                      <Button leftIcon={<HiUpload />} colorScheme="blue" as="span" py={7} px={8}>
                        <Text fontSize="xs" fontWeight="bold">
                          Comprovante de Deslocamento
                          <Text fontSize="x-small">
                            (Print da conversa ou foto do local)
                          </Text>
                        </Text>
                      </Button>
                    </label>
                    {fileName && (
                        <Text fontSize="xs" color="green.500" fontStyle="italic">
                          Arquivo selecionado: {fileName}
                        </Text>
                    )}
                  </VStack>
                </Box>
            }
            <Checkbox
                mt={3}
                colorScheme="whatsapp"
                isChecked={canceledDisplacement}
                onChange={event => setCanceledDisplacement(event.target.checked)}
            >
              <Heading variant="bodyTextMedium" color={'gray.600'}>Cancelado com deslocamento</Heading>
            </Checkbox>

            <Button colorScheme={'blue'} w={'full'} my={5} onClick={handleSubmit}>
              Cancelar ensaio
            </Button>
          </ModalBody>


          {/*<Flex direction="column" m="8" gap="2">*/}
          {/*  <Button variant="primary" onClick={handleSubmit}>*/}
          {/*    Continuar cancelamento*/}
          {/*  </Button>*/}
          {/*  <Button variant="secondary" onClick={onClose}>*/}
          {/*    Sair*/}
          {/*  </Button>*/}
          {/*</Flex>*/}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SessionCancelModal;
